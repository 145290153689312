import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHelmetSafety, faDollarSign, faHeadset, faClock, faMotorcycle, faMapMarkedAlt, faUsers, faRoute } from '@fortawesome/free-solid-svg-icons'; 
import { faFacebook, faInstagram, faTwitter,faWhatsapp } from '@fortawesome/free-brands-svg-icons'; 

import VehicleSection from './vehicle';
import Navbar from './navbar';
import { Link } from 'react-scroll';
import './App.css';

function App() {
  return (
    <div className="App">
      <Navbar />
      
      {/* Header Section */}
      <header
  id="home"
  className="relative bg-cover bg-center h-screen flex items-center justify-center text-center"
  style={{
    backgroundImage: "url('/fheader.gif')",
    backgroundSize: 'cover', // Ensure the GIF covers the entire area
    backgroundPosition: 'center', // Center the image
  }}
>
  <div className="container mx-auto text-black">
    {/* Animated Heading */}
    <h1 className="text-5xl font-bold mb-4 opacity-0 animate-fadeInUp">Bike Rental Ranchi</h1>

    {/* Animated Subheading */}
    <p className="text-5xl font-semibold mb-6 opacity-0 animate-fadeIn delay-200">Experience Ranchi's Best Bike Rental Service</p>

    {/* Button with hover effect */}
    <Link
      to="footer"
      smooth={true}
      duration={500}
      className="bg-teal-600 text-white py-3 cursor-pointer px-6 rounded-lg hover:bg-teal-700 transition duration-300"
    >
      Book Now
    </Link>
  </div>
</header>

      {/* Why Choose Us Section */}
      <section className="py-16 bg-black text-white">
        <div className="container mx-auto">
          <h2 className="text-4xl font-bold underline text-teal-500 text-center mb-12">Why We Are Best In Town?</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="p-6 text-center border-2 border-teal-500 rounded-lg">
              <FontAwesomeIcon icon={faHelmetSafety} size="3x" className="text-teal-500 mb-4" />
              <h3 className="text-xl font-bold">COMPLIMENTARY HELMET</h3>
              <p>We provide a free helmet with every rental to ensure your safety on the road.</p>
            </div>
            <div className="p-6 text-center border-2 border-teal-500 rounded-lg">
              <FontAwesomeIcon icon={faDollarSign} size="3x" className="text-teal-500 mb-4" />
              <h3 className="text-xl font-bold">AFFORDABLE PRICES</h3>
              <p>Get the best rental rates without compromising on quality.</p>
            </div>
            <div className="p-6 text-center border-2 border-teal-500 rounded-lg">
              <FontAwesomeIcon icon={faHeadset} size="3x" className="text-teal-500 mb-4" />
              <h3 className="text-xl font-bold">24/7 SUPPORT</h3>
              <p>We're available anytime to assist you during your rental period.</p>
            </div>
            <div className="p-6 text-center border-2 border-teal-500 rounded-lg">
              <FontAwesomeIcon icon={faClock} size="3x" className="text-teal-500 mb-4" />
              <h3 className="text-xl font-bold">FLEXIBLE RENTAL PLANS</h3>
              <p>Choose the rental duration that best suits your needs.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Our Services Section */}
      <section id="services" className="bg-black py-12">
        <div className="container mx-auto">
          <h2 className="text-4xl text-center underline font-bold text-teal-500 mb-10">Our Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {/* Bike Rentals */}
            <div className="p-6 border-teal-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <FontAwesomeIcon icon={faMotorcycle} size="3x" className="text-teal-500 mb-4" />
              <h3 className="text-xl font-semibold text-white mb-2">Bike Rentals</h3>
              <p className="text-gray-300">
                Rent top-quality bikes for your local or long-distance trips with ease.
              </p>
            </div>

            {/* Long Term Rentals */}
            <div className="p-6 border-teal-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <FontAwesomeIcon icon={faMapMarkedAlt} size="3x" className="text-teal-500 mb-4" />
              <h3 className="text-xl font-semibold text-white mb-2">Long Term Rentals</h3>
              <p className="text-gray-300">
                Flexible long-term rental plans for extended travel needs.
              </p>
            </div>

            {/* Group Discounts */}
            <div className="p-6 border-teal-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <FontAwesomeIcon icon={faUsers} size="3x" className="text-teal-500 mb-4" />
              <h3 className="text-xl font-semibold text-white mb-2">Group Discounts</h3>
              <p className="text-gray-300">
                Special discounts available for group rentals and bulk bookings.
              </p>
            </div>

            {/* Adventure Tours */}
            <div className="p-6 border-teal-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <FontAwesomeIcon icon={faRoute} size="3x" className="text-teal-500 mb-4" />
              <h3 className="text-xl font-semibold text-white mb-2">Adventure Tours</h3>
              <p className="text-gray-300">
                Explore new terrains and scenic routes with our guided bike tours.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Vehicle Section */}
      <VehicleSection />

      {/* Contact Us Section */}
      <section className="py-16 bg-black">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold text-teal-500 mb-8">Get in Touch</h2>
          <form className="max-w-md mx-auto border-2 border-teal-500 bg-gray-800 p-6 shadow-lg rounded-lg">
            <div className="mb-4">
              <label className="block text-left text-white font-semibold mb-2">Name</label>
              <input type="text" required className="w-full px-4 py-2 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500" placeholder="Your Name" />
            </div>
            <div className="mb-4">
              <label className="block text-left text-white font-semibold mb-2">Phone Number</label>
              <input type="number" required className="w-full px-4 py-2 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500" placeholder="Your Phone Number" />
            </div>
            <button type="submit" className="bg-teal-600 text-white px-6 py-2 rounded-lg hover:bg-teal-700 transition duration-300">Get a call</button>
          </form>
        </div>
      </section>

      {/* Footer Section */}
      <footer id="footer" className="bg-black py-8">
  <div className="container mx-auto text-center text-white">
    
    {/* Contact Us Section */}
    <h3 className="text-2xl font-bold text-teal-500 mb-4">Contact Us</h3>
    <p className="text-gray-300 mb-2">Nawa Toli, Kamla Kant Ln, near Pahadi Mandir, Ranchi, Jharkhand 834001</p>
    <p className="text-gray-300 mb-4">Phone: +91  97717 07070</p>
    
    {/* WhatsApp Icon */}
    <div className="flex justify-center items-center space-x-4 mb-4">
      <a 
        href="https://wa.me/9771707070" 
        className="text-white hover:text-teal-500 flex items-center space-x-2"
        target="_blank" 
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faWhatsapp} size="2x" />
        <span className="text-lg">WhatsApp Us</span>
      </a>
    </div>

    {/* Social Media Icons */}
    <div className="flex justify-center space-x-6">
      <a href="https://www.facebook.com" className="text-white hover:text-teal-500">
        <FontAwesomeIcon icon={faFacebook} size="2x" />
      </a>
      <a href="https://www.instagram.com" className="text-white hover:text-teal-500">
        <FontAwesomeIcon icon={faInstagram} size="2x" />
      </a>
      <a href="https://www.twitter.com" className="text-white hover:text-teal-500">
        <FontAwesomeIcon icon={faTwitter} size="2x" />
      </a>
    </div>

    {/* Footer Text */}
    <p className="text-white text-sm mt-6">© 2024 Bike Rental Ranchi. All rights reserved.</p>
  </div>
</footer>

    </div>
  );
}

export default App;
